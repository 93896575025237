import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'
import { getFunctions } from 'firebase/functions'

const firebaseConfig = {
  apiKey: "AIzaSyB2MmTaYa9N78BZs9JBrn3uZ24_oENw3-w",
  authDomain: "hightag-dev.firebaseapp.com",
  projectId: "hightag-dev",
  storageBucket: "hightag-dev.appspot.com",
  messagingSenderId: "342331311997",
  appId: "1:342331311997:web:e9661642a47feb4f2c6e14",
  measurementId: "G-RM7923JDSG"
};

const app = initializeApp(firebaseConfig)

export const auth = getAuth(app)
export const db = getFirestore(app)
export const analytics = getAnalytics(app)
export const functions = getFunctions(app)

export default app