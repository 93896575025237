import { useState } from 'react'
import { Chip, TableRow, TableCell, Typography } from '@mui/material'

import { useFirebase } from '../../contexts/FirebaseContext'
import LocationsDevicesEdit from './LocationsDevicesEdit'

const LocationsDevices = ({ device, trails, locations }) => {
	const { currentUser } = useFirebase()
	const [drawerOpen, setDrawerOpen] = useState(false)
	const styles = {
		truncate: {
			whiteSpace: 'nowrap',
			overflowX: 'hidden',
			textOverflow: 'ellipsis',
		},
		link: {
			color: '#1976D2',
			textDecoration: 'underline',
			cursor: 'pointer',
		}
	}

	const onlineChip = <Chip size='small' color='primary' label='Online' />
	const offlineChip = <Chip size='small' label='Offline' />

	return (
		<>
			<TableRow hover>
				<TableCell sx={styles.truncate}>
					{currentUser?.exec
						? <Typography sx={styles.link} variant='body2' onClick={() => setDrawerOpen(true)} children={device?.id} />
						: device?.id
					}
				</TableCell>
				<TableCell sx={styles.truncate}>{device?.status === 'online' ? onlineChip : offlineChip}</TableCell>
				<TableCell sx={styles.truncate}>{device?.trail}</TableCell>
				<TableCell sx={styles.truncate}>{device?.location}</TableCell>
			</TableRow>
			<LocationsDevicesEdit locations={locations} trails={trails} device={device} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
		</>
	)
}
 
export default LocationsDevices