import { collection, query, where, getCountFromServer } from '@firebase/firestore'
import { Stack, Button, Typography } from '@mui/material'
import { useState } from 'react'

import { db } from '../../firebase/initialize'

const Media = () => {
  const [count, setCount] = useState(0)
  const blacklist = [
    '4zOF196UrWSO5NYmgVnO8jhONmW2',
    'VTgUgVJdpqhDTHjO3Aj0tF89U1k2',
    'Emlw1q0E6JTqGExEg7JGHJdi6743',
    'jDxJEy3DgYR2K1twC3CFm7W3ihH2',
  ]
  const styles = {
    wrapper: {
      paddingTop: 6,
    },
  }

  const handleCountMedia = async () => {
    const mediaColRef = collection(db, 'media')
    const mediaQryRef = query(mediaColRef, where('user_id', 'not-in', blacklist))
    const snapshot = await getCountFromServer(mediaQryRef)
    setCount(snapshot.data().count)
  }

  return (
    <Stack sx={styles.wrapper}>
      <Button variant='contained' size='large' onClick={handleCountMedia}>Count Media</Button>
      <Typography>Media docs: {count}</Typography>
    </Stack>
  )
}

export default Media