import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { useFirebase } from './contexts/FirebaseContext'
import Main from './components/_common/Main'
import Landing from './components/landing/Landing'

const App = () => {
  const { currentUser } = useFirebase()

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={currentUser ? <Main /> : <Landing />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App