import { createContext, useContext, useState, useEffect } from 'react'
// import { doc, getDoc } from '@firebase/firestore'
// import { auth, db } from '../firebase/initialize'
import { auth } from '../firebase/initialize'

const FirebaseContext = createContext()

const useFirebase = () => useContext(FirebaseContext)

const FirebaseProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user?.uid) {
        try {
          // const userDocRef = doc(db, 'users', user.uid)
          // const userDocSnp = await getDoc(userDocRef)
          // const userProfile = userDocSnp.exists() ? userDocSnp.data() : {}

          const idTokenResult = await user.getIdTokenResult()

          setCurrentUser({
            uid: user.uid,
            email: user.email,
            // ...userProfile,
            ...idTokenResult.claims,
          })
        } catch (error) {
          console.error('Error fetching user data:', error)
        }
      } else {
        setCurrentUser(null)
      }
    })

    return () => unsubscribe()
  }, [])

  const value = { currentUser, }

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  )
}

export { useFirebase }
export default FirebaseProvider