import { useState } from 'react'
import { TableRow, TableCell, Checkbox, Typography } from '@mui/material'

import { useFirebase } from '../../contexts/FirebaseContext'
import LocationsTrailsEdit from './LocationsTrailsEdit'

const LocationsTrailsItem = ({ locations, trail, checkedTrails, setCheckedTrails, }) => {
	const { currentUser } = useFirebase()
	const [drawerOpen, setDrawerOpen] = useState(false)
	const styles = {
		truncate: {
			whiteSpace: 'nowrap',
			overflowX: 'hidden',
			textOverflow: 'ellipsis',
		},
		link: {
			color: '#1976D2',
			textDecoration: 'underline',
			cursor: 'pointer',
		}
	}

	const handleCheckbox = () => {
		checkedTrails.includes(trail.id)
			? setCheckedTrails(checkedTrails.filter(id => id !== trail.id))
			: setCheckedTrails([...checkedTrails, trail.id])
	}

	return (
		<>
			<TableRow hover>
				<TableCell padding='checkbox'>
					<Checkbox
						checked={!!checkedTrails.includes(trail.id)}
						onChange={handleCheckbox}
					/>
				</TableCell>
				<TableCell sx={styles.truncate}>
					{currentUser?.exec
						? <Typography sx={styles.link} variant='body2' onClick={() => setDrawerOpen(true)} children={trail?.name} />
						: trail?.name
					}
				</TableCell>
				<TableCell sx={styles.truncate}>{trail?.trail_system}</TableCell>
			</TableRow>
			<LocationsTrailsEdit locations={locations} trail={trail} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
		</>
	)
}
 
export default LocationsTrailsItem