import { useState, useEffect } from 'react'
import { doc, updateDoc } from '@firebase/firestore'
import { Button, Drawer, TextField, Stack, Typography, Switch } from '@mui/material'

import { db } from '../../firebase/initialize'

const LocationsLocationsEdit = ({ location, drawerOpen, setDrawerOpen }) => {
	const [city, setCity] = useState('')
	const [address, setAddress] = useState('')
	const [lat, setLat] = useState('')
	const [lng, setLng] = useState('')
	const [name, setName] = useState('')
	const [timeStart, setTimeStart] = useState('')
	const [timeEnd, setTimeEnd] = useState('')
	const [state, setState] = useState('')
	const [hidden, setHidden] = useState(location.hidden || false)

	const styles = {
		drawer: {
			minWidth: 320,
			padding: 4,
		},
	}

	useEffect(() => {
		if (location && drawerOpen) {
			setName(location.location_name || '')
			setAddress(location.address || '')
			setCity(location.city || '')
			setState(location.state || '')
			setLat(location.lat || '')
			setLng(location.lng || '')
			setTimeStart(location.ops_time_start || '')
			setTimeEnd(location.ops_time_end || '')
			setHidden(location.hidden || false)
		}
	}, [location, drawerOpen])

	const updateLocation = async () => {
		if (!city || !lat || !lng || !name || !timeStart || !timeEnd || !state || !address) {
			console.error('Must input all required fields.')
			return
		}

		try {
			await updateDoc(doc(db, 'locations', location.id), {
				address,
				city,
				hidden,
				lat: Number(lat),
				lng: Number(lng),
				location_name: name,
				ops_time_start: timeStart,
				ops_time_end: timeEnd,
				state,
			})
			console.log('Document successfully updated!')
		} catch (error) {
			console.error(`Error updating document: ${error}`)
		}
	}

	const handleHiddenSwitch = (event) => {
		setHidden(event.target.checked)
	}

	return (
		<Drawer anchor='right' open={drawerOpen} onClose={() => setDrawerOpen(false)}>
			<Stack spacing={2} sx={styles.drawer}>
				<TextField value={name} onChange={(e) => setName(e.target.value)} label='Location name' variant='outlined' required />
				<TextField value={address} onChange={(e) => setAddress(e.target.value)} label='Street address' variant='outlined' required />
				<TextField value={city} onChange={(e) => setCity(e.target.value)} label='City' variant='outlined' required />
				<TextField value={state} onChange={(e) => setState(e.target.value)} label='State' variant='outlined' required />
				<TextField type='number' value={lat} onChange={(e) => setLat(e.target.value)} label='Latitude' variant='outlined' required />
				<TextField type='number' value={lng} onChange={(e) => setLng(e.target.value)} label='Longitude' variant='outlined' required />
				<TextField value={timeStart} onChange={(e) => setTimeStart(e.target.value)} label='Ops. start time' variant='outlined' required />
				<TextField value={timeEnd} onChange={(e) => setTimeEnd(e.target.value)} label='Ops. end time' variant='outlined' required />
				<Stack direction='row' alignItems='center'>
					<Typography variant='body2'>Set as hidden</Typography>
					<Switch checked={hidden} onChange={handleHiddenSwitch} />
				</Stack>
				<Stack gap={1.5}>
					<Button variant='contained' onClick={updateLocation} children='Update location' />
					<Button color='warning' children='Delete location' />
				</Stack>
			</Stack>
		</Drawer>
	)
}

export default LocationsLocationsEdit