import { Stack, Paper, TableContainer, Table, TableHead, TableRow, TableCell, Checkbox, TableBody, TableFooter, } from '@mui/material'

import { useFirebase } from '../../contexts/FirebaseContext'
import LocationsLocationsItem from './LocationsLocationsItem'
import LocationsLocationsNew from './LocationsLocationsNew'

const LocationsLocations = ({ locations, checkedLocations, setCheckedLocations, }) => {
	const { currentUser } = useFirebase()
	const columns = [
		{ id: 'location', label: 'Location', width: 200 },
		{ id: 'city', label: 'City', width: 120 },
		{ id: 'state', label: 'State', width: 120 },
	]

	const allChecked = locations.every(location => checkedLocations.includes(location.id))
	const noneChecked = locations.every(location => !checkedLocations.includes(location.id)) || locations.length === 0
	
	const handleCheckbox = () => {
		if (allChecked) setCheckedLocations([])
		if (noneChecked) setCheckedLocations(locations.map(location => location.id))
		if (!allChecked && !noneChecked) setCheckedLocations(locations.map(location => location.id))
	}

	return (
		<Paper sx={{ height: '100%', maxHeight: 'calc(100vh - 96px)', }}>
			<Stack sx={{ height: '100%', }} alignItems='stretch'>
				<TableContainer sx={{ flex: 1, height: 480, }}>
					<Table size='small' stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell padding='checkbox'>
									<Checkbox
										checked={allChecked}
										indeterminate={!allChecked && !noneChecked}
										disabled={locations.length === 0}
										onChange={handleCheckbox}
									/>
								</TableCell>
								{columns.map((column) => (
									<TableCell key={column.id} align={column.align} sx={{ minWidth: column.width, maxWidth: column.width, }}>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{locations.map((location) => (
								<LocationsLocationsItem key={location.id} location={location} checkedLocations={checkedLocations} setCheckedLocations={setCheckedLocations} />
							))}
						</TableBody>
					</Table>
				</TableContainer>
				{currentUser?.exec &&
					<Table>
						<TableFooter>
							<TableRow>
								<TableCell align='right'>
									<Stack direction='row' gap={1} justifyContent='flex-end'>
										<LocationsLocationsNew />
									</Stack>
								</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
				}
			</Stack>
		</Paper>
	)
}
 
export default LocationsLocations