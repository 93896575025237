import ReactDOM from 'react-dom/client'

import FirebaseProvider from './contexts/FirebaseContext'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <FirebaseProvider>
    <App />
  </FirebaseProvider>
)