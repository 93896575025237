import { signOut } from 'firebase/auth'
import { TabList } from '@mui/lab'
import { Box, Tab } from '@mui/material'

import { auth } from '../../firebase/initialize'
import { useFirebase } from '../../contexts/FirebaseContext'

const Navbar = ({ handleChange }) => {
	const { currentUser } = useFirebase()
	const styles = {
		wrapper: {
			position: 'fixed',
			top: 0,
			left: 0,
			right: 0,
			zIndex: 1000,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			borderBottom: 1,
			borderColor: 'divider',
			backgroundColor: '#FFFFFF',
			overflowX: 'auto',
			whiteSpace: 'nowrap',
		}
	}

	const handleSignOut = async () => {
		try {
			await signOut(auth)
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<Box sx={styles.wrapper}>
			<TabList sx={{ display: 'flex' }} onChange={handleChange} variant="scrollable" scrollButtons="auto">
				<Tab label='Locations' value='locations'/>
				{currentUser?.exec && <Tab label='Users' value='users'/>}
				{currentUser?.exec && <Tab label='Media' value='media'/>}
				<Tab label='Sign out' value='sign out' onClick={handleSignOut} />
			</TabList>
		</Box>
	)
}

export default Navbar