import { useState, useEffect } from 'react'
import { doc, updateDoc } from '@firebase/firestore'
import { Button, Drawer, TextField, Stack, FormControl, InputLabel, Select, MenuItem } from '@mui/material'

import { db } from '../../firebase/initialize'

const LocationsDevicesEdit = ({ locations, trails, device, drawerOpen, setDrawerOpen }) => {
  const [lat, setLat] = useState('')
  const [lng, setLng] = useState('')
  const [location, setLocation] = useState('')
  const [trail, setTrail] = useState('')

  const styles = {
    drawer: {
      minWidth: 320,
      padding: 4,
    },
  }

  useEffect(() => {
    if (device && drawerOpen) {
      setLat(device.lat || '')
      setLng(device.lng || '')
      setLocation(locations.find(location => location?.id === device.location_id) || '')
      setTrail(trails.find(trail => trail?.id === device.trail_id) || '')
    }
  }, [locations, trails, device, drawerOpen])

  const handleSelectLocation = (event) => {
    const selectedId = event.target.value
    const selectedLocation = locations.find(location => location.id === selectedId)
    setLocation(selectedLocation)
  }

  const handleSelectTrail = (event) => {
    const selectedId = event.target.value
    const selectedTrail = trails.find(trail => trail.id === selectedId)
    setTrail(selectedTrail)
  }

  const updateDevice = async () => {
    if (!lat || !lng || !location || !trail) {
      console.error('Must input all required fields.')
      return
    }

    try {
      await updateDoc(doc(db, 'devices', device.id), {
        lat: Number(lat),
        lng: Number(lng),
        location_id: location.id,
        trail_id: trail.id,
      })
      console.log('Document successfully updated!')
    } catch (error) {
      console.error(`Error updating document: ${error}`)
    }
  }

  return (
    <Drawer anchor='right' open={drawerOpen} onClose={() => setDrawerOpen(false)}>
      <Stack spacing={2} sx={styles.drawer}>
        <FormControl>
          <InputLabel>Location</InputLabel>
          <Select value={location.id || ''} onChange={handleSelectLocation} label='Location'>
            {locations.map(location => (
              <MenuItem key={location.id} value={location.id}>
                {location.location_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel>Trail</InputLabel>
          <Select value={trail.id || ''} onChange={handleSelectTrail} label='Trail'>
            {trails.map(trail => (
              <MenuItem key={trail.id} value={trail.id}>
                {trail.trail_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField type='number' value={lat} onChange={(e) => setLat(e.target.value)} label='Latitude' variant='outlined' required />
        <TextField type='number' value={lng} onChange={(e) => setLng(e.target.value)} label='Longitude' variant='outlined' required />
        <Stack gap={1.5}>
          <Button variant='contained' onClick={updateDevice} children='Update device' />
          <Button color='warning' children='Delete device' />
        </Stack>
      </Stack>
    </Drawer>
  )
}

export default LocationsDevicesEdit