import { useRef } from 'react'
import { httpsCallable } from 'firebase/functions'
import { Stack, Button, TextField } from '@mui/material'

import { functions } from '../../firebase/initialize'
import UsersAdminTable from './UsersAdminTable'

const Users = () => {
  const emailRef = useRef()
  const addAdmin = httpsCallable(functions, 'addAdmin')
	const styles = {
		wrapper: {
			paddingTop: 6,
		},
	}

  const handleAddAdmin = async () => {
    try {
      await addAdmin({ email: emailRef.current.value }).then(result => console.log(result?.data))
    } catch (error) {
      console.log(error)
    }
  }

	return (
    <Stack sx={styles.wrapper}>
			<TextField type='email' inputRef={emailRef} label='Email address' variant='outlined' />
			<Button variant='contained' size='large' onClick={handleAddAdmin}>Set as admin</Button>
			<UsersAdminTable />
    </Stack>
	)
}
 
export default Users