import { Stack, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, } from '@mui/material'

import { useFirebase } from '../../contexts/FirebaseContext'
import { theme } from '../../themes/theme'
import LocationsDevicesItem from './LocationsDevicesItem'
import LocationsDevicesNew from './LocationsDevicesNew'

const LocationsDevices = ({ devices, trails, locations }) => {
	const { currentUser } = useFirebase()
	const columns = [
		{ id: 'device', label: 'Device', width: 120 },
		{ id: 'status', label: 'Status', width: 120 },
		{ id: 'trail', label: 'Trail', width: 120 },
		{ id: 'location', label: 'Location', width: 200 },
	]
	const styles = {
		wrapper: {
			height: '100%',
			maxHeight: 'calc(100vh - 96px)',
			[theme.breakpoints.down('md')]: {
				maxHeight: 'none',
			}
		},
	}

	return (
		<Paper sx={styles.wrapper}>
			<Stack sx={{ height: '100%', }} alignItems='stretch'>
				<TableContainer sx={{ flex: 1, }}>
					<Table size='small' stickyHeader>
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell key={column.id} align={column.align} sx={{ minWidth: column.width, maxWidth: column.width, }}>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{devices.map((device) => (
								<LocationsDevicesItem key={device.id} device={device} trails={trails} locations={locations} />
							))}
						</TableBody>
					</Table>
				</TableContainer>
				{currentUser?.exec &&
					<Table>
						<TableFooter>
							<TableRow>
								<TableCell align='right'>
									<Stack direction='row' gap={1} justifyContent='flex-end'>
										<LocationsDevicesNew locations={locations} trails={trails} />
									</Stack>
								</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
				}
			</Stack>
		</Paper>
	)
}
 
export default LocationsDevices