import { useState } from 'react'
import { TabContext, TabPanel } from '@mui/lab'

import { useFirebase } from '../../contexts/FirebaseContext'
import Navbar from './Navbar'
import Media from '../media/Media'
import Users from '../users/Users'
import Locations from '../locations/Locations'

const Main = () => {
	const { currentUser } = useFirebase()
	const [value, setValue] = useState('locations')

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	return (
		<TabContext value={value}>
			<Navbar handleChange={handleChange} />
			<TabPanel value='locations'><Locations /></TabPanel>
			{currentUser?.exec && <TabPanel value='users'><Users /></TabPanel>}
			{currentUser?.exec && <TabPanel value='media'><Media /></TabPanel>}
		</TabContext>
	)
}
 
export default Main