import { useState, useEffect } from 'react'
import { collection, onSnapshot } from '@firebase/firestore'
import { Stack, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { db } from '../../firebase/initialize'
import { theme } from '../../themes/theme'
import { useWindow } from '../../utilities/useWindow'
import LocationsLocations from './LocationsLocations'
import LocationsTrails from './LocationsTrails'
import LocationsDevices from './LocationsDevices'

const Locations = () => {
	const { width } = useWindow()
	const [locations, setLocations] = useState([])
	const [checkedLocations, setCheckedLocations] = useState([])
	const [trails, setTrails] = useState([])
	const [checkedTrails, setCheckedTrails] = useState([])
	const [devices, setDevices] = useState([])
	const [filteredTrails, setFilteredTrails] = useState([])
	const [filteredDevices, setFilteredDevices] = useState([])
	const [dataLoaded, setDataLoaded] = useState({ locations: false, trails: false })

	const styles = {
		wrapper: {
			paddingTop: 6,
			height: 'calc(100vh - 48px - 48px)',
			[theme.breakpoints.down('md')]: {
				height: 'auto',
			},
		},
	}

	useEffect(() => {
		const unsubLocations = onSnapshot(collection(db, 'locations'), (snapshot) => {
			const locationsArr = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })).filter(location => location.hidden !== true)
			// const checkedLocationsArr = locationsArr.map(location => location.id)
			let checkedLocationsArr = []

			setLocations(locationsArr)
			setCheckedLocations(checkedLocationsArr)
			setDataLoaded(prev => ({ ...prev, locations: true }))
		})

		const unsubTrails = onSnapshot(collection(db, 'trails'), (snapshot) => {
			const trailsArr = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })).filter(trail => trail.hidden !== true)
			const checkedTrailsArr = trailsArr.map(trail => trail.id)

			setTrails(trailsArr)
			setCheckedTrails(checkedTrailsArr)
			setDataLoaded(prev => ({ ...prev, trails: true }))
		})

		return () => {
			unsubLocations()
			unsubTrails()
		}
	}, [])

	useEffect(() => {
		if (dataLoaded.locations && dataLoaded.trails) {
			const unsubDevices = onSnapshot(collection(db, 'devices'), (snapshot) => {
				const getTrailById = (trail_id) => {
					const trail = trails.find(t => t.id === trail_id)
					return trail ? trail.trail_name : 'Unknown trail'
				}

				const getLocationById = (location_id) => {
					const location = locations.find(l => l.id === location_id)
					return location ? location.location_name : 'Unknown location'
				}

				const devicesArr = snapshot.docs
					.map(doc => ({
						id: doc.id,
						...doc.data(),
						trail: getTrailById(doc.data().trail_id),
						location: getLocationById(doc.data().location_id),
					}))
					.filter(device => device.hidden !== true)

				setDevices(devicesArr)
			})

			return () => {
				unsubDevices()
			}
		}
	}, [dataLoaded, trails, locations])

	const filterTrails = () => {
		return trails.filter(trail => 
			checkedLocations.includes(trail.location_id) && trail.hidden !== true
		)
	}

	const filterDevices = () => {
		return devices.filter(device => {
			const trail = trails.find(trail => trail.id === device.trail_id)
			return trail 
				&& checkedTrails.includes(device.trail_id) 
				&& checkedLocations.includes(trail.location_id) 
				&& trail.hidden !== true 
				&& device.hidden !== true
		})
	}

	useEffect(() => {
		const filteredTrailsResult = filterTrails()
		const filteredDevicesResult = filterDevices()

		setFilteredTrails(filteredTrailsResult)
		setFilteredDevices(filteredDevicesResult)
	// eslint-disable-next-line
	}, [checkedLocations, checkedTrails, trails, devices])

	return (
		<Stack sx={styles.wrapper} alignItems='stretch' gap={3}>
			<Grid sx={{ flex: 1 }} container spacing={3}>
				<Grid xs={12} md={4}>
					{/* {width < 960 ? (
						<Accordion>
							<AccordionSummary onClick={(event) => event.preventDefault()}>
								<Typography>Locations</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<LocationsLocations
									locations={locations}
									checkedLocations={checkedLocations}
									setCheckedLocations={setCheckedLocations}
								/>
							</AccordionDetails>
						</Accordion>
					) : ( */}
							<LocationsLocations
								locations={locations}
								checkedLocations={checkedLocations}
								setCheckedLocations={setCheckedLocations}
							/>
					{/* )} */}
				</Grid>
				<Grid xs={12} md={4}>
					{/* {width < 960 ? (
						<Accordion>
							<AccordionSummary onClick={(event) => event.preventDefault()}>
								<Typography>Trails</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<LocationsTrails
									trails={filteredTrails}
									locations={locations}
									checkedTrails={checkedTrails}
									setCheckedTrails={setCheckedTrails}
								/>
							</AccordionDetails>
						</Accordion>
					) : ( */}
							<LocationsTrails
								trails={filteredTrails}
								locations={locations}
								checkedTrails={checkedTrails}
								setCheckedTrails={setCheckedTrails}
							/>
					{/* )} */}
				</Grid>
				<Grid xs={12} md={4}>
					<LocationsDevices
						devices={filteredDevices}
						trails={trails}
						locations={locations}
					/>
				</Grid>
			</Grid>
		</Stack>
	)
}

export default Locations