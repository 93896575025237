import { Stack, Paper, TableContainer, Table, TableHead, TableRow, TableCell, Checkbox, TableBody, TableFooter, Button } from '@mui/material'

import { useFirebase } from '../../contexts/FirebaseContext'
import LocationsTrailsItem from './LocationsTrailsItem'

const LocationsTrails = ({ locations, trails, checkedTrails, setCheckedTrails, }) => {
	const { currentUser } = useFirebase()
	const columns = [
		{ id: 'trail', label: 'Trail', width: 160 },
		{ id: 'location', label: 'Location', width: 160 },
	]

	const allChecked = trails.every(trail => checkedTrails.includes(trail.id))
	const noneChecked = trails.every(trail => !checkedTrails.includes(trail.id))
	
	const handleCheckbox = () => {
		if (allChecked) setCheckedTrails([])
		if (noneChecked) setCheckedTrails(trails.map(trail => trail.id))
		if (!allChecked && !noneChecked) setCheckedTrails(trails.map(trail => trail.id))
	}

	return (
		<Paper sx={{ height: '100%', maxHeight: 'calc(100vh - 96px)', }}>
			<Stack sx={{ height: '100%', }} alignItems='stretch'>
				<TableContainer sx={{ flex: 1, height: 480, }}>
					<Table size='small' stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell padding='checkbox'>
									<Checkbox
										checked={allChecked && trails.length !== 0}
										indeterminate={!allChecked && !noneChecked}
										disabled={trails.length === 0}
										onChange={handleCheckbox}
									/>
								</TableCell>
								{columns.map((column) => (
									<TableCell key={column.id} align={column.align} sx={{ minWidth: column.width, maxWidth: column.width, }}>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{trails.map((trail) => (
								<LocationsTrailsItem
									key={trail.id}
									locations={locations}
									trail={trail}
									checkedTrails={checkedTrails}
									setCheckedTrails={setCheckedTrails}
								/>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				{currentUser?.exec &&
					<Table>
						<TableFooter>
							<TableRow>
								<TableCell align='right'>
									<Stack direction='row' gap={1} justifyContent='flex-end'>
										<Button size='small' variant='contained' children='New trail' />
									</Stack>
								</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
				}
			</Stack>
		</Paper>
	)
}
 
export default LocationsTrails