import { useEffect, useState } from 'react'
import { httpsCallable } from 'firebase/functions'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, FormControlLabel, Switch } from '@mui/material'

import { functions } from '../../firebase/initialize'

const UsersAdminTable = () => {
  const [adminUsers, setAdminUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchAdminUsers = async () => {
      const listAdmins = httpsCallable(functions, 'listAdmins')

      try {
        setLoading(true)
        const result = await listAdmins()
        if (result.data.error) throw new Error(result.data.error)
        setAdminUsers(result.data.result || [])
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchAdminUsers()
  }, [])

	const handleToggleExec = async (email, isExec) => {
		const action = isExec ? 'revokeExec' : 'addExec'
		const toggleExec = httpsCallable(functions, action)
	
		try {
			const result = await toggleExec({ email })
			if (result.data.error) throw new Error(result.data.error)
			alert(result.data.result)
			setAdminUsers(adminUsers.map(user =>
				user.email === email ? { ...user, exec: !user.exec } : user
			))
		} catch (err) {
			alert(`Error: ${err.message}`)
		}
	}

  const handleRevokeAdmin = async (email) => {
    const revokeAdmin = httpsCallable(functions, 'revokeAdmin')

    try {
      const result = await revokeAdmin({ email })
      if (result.data.error) throw new Error(result.data.error)
      alert(result.data.result)
      setAdminUsers(adminUsers.filter(user => user.email !== email))
    } catch (err) {
      alert(`Error: ${err.message}`)
    }
  }

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error}</div>

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Admin User</TableCell>
            <TableCell>Exec</TableCell>
            <TableCell align='right'>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {adminUsers.map((user) => (
            <TableRow key={user.uid}>
              <TableCell>{user.email}</TableCell>
              <TableCell>
								<FormControlLabel
									control={
										<Switch
											checked={user.exec || false}
											onChange={() => handleToggleExec(user.email, user.exec)}
										/>
									}
									label={user.exec ? 'Exec' : 'Not Exec'}
								/>
              </TableCell>
              <TableCell align='right'>
                <Button variant='contained' size='small' onClick={() => handleRevokeAdmin(user.email)} children='Remove' />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default UsersAdminTable