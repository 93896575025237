import { useRef, useState } from 'react'
import { signInWithEmailAndPassword, getIdTokenResult } from 'firebase/auth'
import { Box, Button, Typography } from '@mui/material'

import { auth } from '../../firebase/initialize'

const Landing = () => {
  const emailRef = useRef()
  const passwordRef = useRef()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const handleSignIn = async () => {
    setLoading(true)
    setError(null)

    try {
      const userCredential = await signInWithEmailAndPassword(auth, emailRef.current.value, passwordRef.current.value)
      const tokenResult = await getIdTokenResult(userCredential.user)
      
      if (tokenResult.claims.admin) {
        // User is an admin, proceed to the admin area
        // Replace this comment with your navigation logic, e.g., navigate('/admin')
        console.log("Admin user logged in")
      } else {
        // User is not an admin
        setError("You do not have permission to access this area.")
        await auth.signOut()
      }
    } catch (error) {
      console.error(error)
      setError("Failed to sign in. Please check your credentials.")
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box>
      <input type='email' ref={emailRef} placeholder='Email address' required />
      <input type='password' ref={passwordRef} placeholder='Password' required />
      <Button variant='contained' onClick={handleSignIn} disabled={loading}>Sign in</Button>
      {error && <Typography color='error'>{error}</Typography>}
    </Box>
  )
}

export default Landing