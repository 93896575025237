import { useState, useEffect } from 'react'
import { doc, updateDoc } from '@firebase/firestore'
import { Button, Drawer, TextField, Stack, FormControl, InputLabel, Select, MenuItem, } from '@mui/material'

import { db } from '../../firebase/initialize'

const LocationsTrailsEdit = ({ locations, trail, drawerOpen, setDrawerOpen }) => {
	const [lat, setLat] = useState('')
	const [lng, setLng] = useState('')
	const [location, setLocation] = useState('')
	const [name, setName] = useState('')
	const [hub, setHub] = useState('')
	const [type, setType] = useState('')
	const [difficulty, setDifficulty] = useState('')

	const styles = {
		drawer: {
			minWidth: 320,
			padding: 4,
		},
	}

	useEffect(() => {
		if (trail && drawerOpen) {
			setLat(trail.lat || '')
			setLng(trail.lng || '')
			setLocation(locations.find(location => location?.id === trail.location_id) || '')
			setName(trail.name || '')
			setHub(trail.trail_hub || '')
			setType(trail.type || '')
			setDifficulty(trail.difficulty || '')
		}
	}, [locations, trail, drawerOpen])

	const handleSelect = (event) => {
    const selectedId = event.target.value
    const selectedLocation = locations.find(location => location.id === selectedId)
    setLocation(selectedLocation)
  }

	const updateTrail = async () => {
		if (!lat || !lng || !location || !name || !type || !difficulty) {
			console.error('Must input all required fields.')
			return
		}

		try {
			await updateDoc(doc(db, 'trails', trail.id), {
				lat: Number(lat),
				lng: Number(lng),
				locationId: location.id,
				location_id: location.id,
				name,
				trail_hub: hub,
				trail_name: name,
				trail_system: location.location_name,
				type,
				difficulty,
			})
			console.log('Document successfully updated!')
		} catch (error) {
			console.error(`Error updating document: ${error}`)
		}
	}

	return (
		<Drawer anchor='right' open={drawerOpen} onClose={() => setDrawerOpen(false)}>
			<Stack spacing={2} sx={styles.drawer}>
				<TextField value={name} onChange={(e) => setName(e.target.value)} label='Name' variant='outlined' required />
				<TextField value={type} onChange={(e) => setType(e.target.value)} label='Type' variant='outlined' required />
				<TextField value={difficulty} onChange={(e) => setDifficulty(e.target.value)} label='Difficulty' variant='outlined' required />
				<FormControl>
					<InputLabel>Location</InputLabel>
					<Select value={location.id || ''} onChange={handleSelect} label='Location'>
            {locations.map(location => (
              <MenuItem key={location.id} value={location.id}>
                {location.location_name}
              </MenuItem>
            ))}
          </Select>
				</FormControl>
				<TextField value={hub} onChange={(e) => setHub(e.target.value)} label='Trail hub' variant='outlined' />
				<TextField type='number' value={lat} onChange={(e) => setLat(e.target.value)} label='Latitude' variant='outlined' required />
				<TextField type='number' value={lng} onChange={(e) => setLng(e.target.value)} label='Longitude' variant='outlined' required />
				<Stack gap={1.5}>
					<Button variant='contained' onClick={updateTrail} children='Update location' />
					<Button color='warning' children='Delete location' />
				</Stack>
			</Stack>
		</Drawer>
	)
}

export default LocationsTrailsEdit