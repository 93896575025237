import { useState } from 'react'
import { TableRow, TableCell, Checkbox, Typography } from '@mui/material'

import { useFirebase } from '../../contexts/FirebaseContext'
import LocationsLocationsEdit from './LocationsLocationsEdit'

const LocationsLocationsItem = ({ location, checkedLocations, setCheckedLocations, }) => {
	const { currentUser } = useFirebase()
	const [drawerOpen, setDrawerOpen] = useState(false)
	const styles = {
		truncate: {
			whiteSpace: 'nowrap',
			overflowX: 'hidden',
			textOverflow: 'ellipsis',
		},
		link: {
			color: '#1976D2',
			textDecoration: 'underline',
			cursor: 'pointer',
		}
	}

	const handleCheckbox = () => {
		checkedLocations.includes(location.id)
			? setCheckedLocations(checkedLocations.filter(id => id !== location.id))
			: setCheckedLocations([...checkedLocations, location.id])
	}

	return (
		<>
			<TableRow hover>
				<TableCell padding='checkbox'>
					<Checkbox
						checked={!!checkedLocations.includes(location.id)}
						onChange={handleCheckbox}
					/>
				</TableCell>
				<TableCell sx={styles.truncate}>
					{currentUser?.exec
						? <Typography sx={styles.link} variant='body2' onClick={() => setDrawerOpen(true)} children={location?.location_name} />
						: location?.location_name
					}
				</TableCell>
				<TableCell sx={styles.truncate}>{location?.city}</TableCell>
				<TableCell sx={styles.truncate}>{location?.state}</TableCell>
			</TableRow>
			<LocationsLocationsEdit location={location} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
		</>
	)
}
 
export default LocationsLocationsItem