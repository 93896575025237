import { useRef, useState } from 'react'
import { collection, addDoc } from '@firebase/firestore'
import { Button, Drawer, TextField, Stack, Typography, Switch } from '@mui/material'

import { db } from '../../firebase/initialize'

const LocationsLocationsNew = () => {
	const cityRef = useRef(null)
	const latRef = useRef(null)
	const lngRef = useRef(null)
	const nameRef = useRef(null)
	const timeStartRef = useRef(null)
	const timeEndRef = useRef(null)
	const stateRef = useRef(null)
	const addressRef = useRef(null)
	const [hidden, setHidden] = useState(false)
	const [drawerOpen, setDrawerOpen] = useState(false)
	const styles = {
		drawer: {
			minWidth: 320,
			padding: 4,
		},
	}

	const createNewLocation = async () => {
		const city = cityRef.current?.value
		const lat = Number(latRef.current?.value)
		const lng = Number(lngRef.current?.value)
		const name = nameRef.current?.value
		const timeStart = timeStartRef.current?.value
		const timeEnd = timeEndRef.current?.value
		const state = stateRef.current?.value
		const address = stateRef.current?.value

		if (!city || !lat || !lng || !name || !timeStart || !timeEnd || !state || !address) {
			console.error('Must input all required fields.')
			return
		}

		try {
			await addDoc(collection(db, 'locations'), {
				address,
				city,
				hidden,
				lat,
				lng,
				location_name: name,
				ops_time_start: timeStart,
				ops_time_end: timeEnd,
				state,
			})
		} catch (error) {
			console.error(`Error writing document: ${error}`)
		}
	}

	const handleHiddenSwitch = (event) => {
		setHidden(event.target.checked)
	}

	return (
		<>
			<Button variant='contained' size='small' onClick={() => setDrawerOpen(true)} children='New location' />
			<Drawer anchor='right' open={drawerOpen} onClose={() => setDrawerOpen(false)}>
				<Stack spacing={2} sx={styles.drawer}>
					<TextField inputRef={nameRef} label='Location name' variant='outlined' required />
					<TextField inputRef={addressRef} label='Street address' variant='outlined' required />
					<TextField inputRef={cityRef} label='City' variant='outlined' required />
					<TextField inputRef={stateRef} label='State' variant='outlined' required />
					<TextField type='number' inputRef={latRef} label='Latitude' variant='outlined' required />
					<TextField type='number' inputRef={lngRef} label='Longitude' variant='outlined' required />
					<TextField inputRef={timeStartRef} label='Ops. start time' variant='outlined' required />
					<TextField inputRef={timeEndRef} label='Ops. end time' variant='outlined' required />
					<Stack direction='row' alignItems='center'>
						<Typography variant='body2'>Set as hidden</Typography>
						<Switch checked={hidden} onClick={handleHiddenSwitch} />
					</Stack>
					<Button variant='contained' onClick={createNewLocation} children='Create location' />
				</Stack>
			</Drawer>
		</>
	)
}
 
export default LocationsLocationsNew