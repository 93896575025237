import { useState } from 'react'
import { doc, setDoc } from '@firebase/firestore'
import { Button, Drawer, TextField, Stack, FormControl, InputLabel, Select, MenuItem } from '@mui/material'

import { db } from '../../firebase/initialize'

const LocationsDevicesNew = ({ locations, trails, }) => {
	const [name, setName] = useState('')
	const [location, setLocation] = useState('')
	const [trail, setTrail] = useState('')
	const [lat, setLat] = useState('')
	const [lng, setLng] = useState('')
	const [drawerOpen, setDrawerOpen] = useState(false)
	const styles = {
		drawer: {
			minWidth: 320,
			padding: 4,
		},
	}

	const createNewDevice = async () => {
		if (!name || !location || !trail || !lat || !lng) {
			console.error('Must input all required fields.')
			return
		}

		try {
			await setDoc(doc(db, 'devices', name), {
				capturePoint_id: name,
				location_id: location.id,
				trail_id: trail.id,
				lat: Number(lat),
				lng: Number(lng),
			})
		} catch (error) {
			console.error(`Error writing document: ${error}`)
		}
	}

	return (
		<>
			<Button variant='contained' size='small' onClick={() => setDrawerOpen(true)} children='New device' />
			<Drawer anchor='right' open={drawerOpen} onClose={() => setDrawerOpen(false)}>
				<Stack spacing={2} sx={styles.drawer}>
					<TextField value={name} onChange={(e) => setName(e.target.value)}  label='Device ID' variant='outlined' required />
					<FormControl>
						<InputLabel>Location</InputLabel>
						<Select
							value={location.id || ''}
							onChange={(e) => setLocation(locations.find(location => location.id === e.target.value))}
							label='Location'
							required
						>
							{locations.map(location => (
								<MenuItem key={location.id} value={location.id}>
									{location.location_name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl>
						<InputLabel>Trail</InputLabel>
						<Select
							value={trail.id || ''}
							onChange={(e) => setTrail(trails.find(trail => trail.id === e.target.value))}
							label='Trail'
							required
						>
							{trails.map(trail => (
								<MenuItem key={trail.id} value={trail.id}>
									{trail.trail_name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<TextField type='number' value={lat} onChange={(e) => setLat(e.target.value)} label='Latitude' variant='outlined' required />
					<TextField type='number' value={lng} onChange={(e) => setLng(e.target.value)} label='Longitude' variant='outlined' required />
					<Button variant='contained' onClick={createNewDevice} children='Create device' />
				</Stack>
			</Drawer>
		</>
	)
}
 
export default LocationsDevicesNew